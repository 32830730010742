<script setup>
import { ref, watch, onMounted, onUnmounted } from "vue";
import Contents from "@/components/sections/Contents.vue";
import SectionTitle from "@/components/additional/SectionTitle.vue";
import { usePrivacyStore } from '@/store/privacy';

const privacyStore = usePrivacyStore();

const blockRefs = ref([]);
const sections = ref([]);

const updateActiveSection = () => {
  if(privacyStore.isClicked) return;
  let currentSection = -1;

  sections.value.forEach((section, index) => {
    const rect = section.getBoundingClientRect();
    if (rect.top <= window.innerHeight / 3) {
      currentSection = index;
    }
  });

  if (currentSection !== -1) {
    privacyStore.setBlockIndex(currentSection);
  }
};

onMounted(() => {
  sections.value = blockRefs.value;

  window.addEventListener('scroll', updateActiveSection);
  updateActiveSection();
});

onUnmounted(() => {
  window.removeEventListener('scroll', updateActiveSection);
});

watch(() => privacyStore.blockId, (newValue) => {
  if (privacyStore.isClicked) {
    const block = document.getElementById(`${privacyStore.blockId}`);
    if (block) {
      block.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        privacyStore.setClicked(false);
      }, 1000);
    }
  }
});
</script>

<template>
  <section class="p-privacy">
    <div class="container">
      <SectionTitle>navigation</SectionTitle>
      <div class="p-privacy__content flex justify-between">
        <Contents />
        <div class="body flex flex-col">
          <div class="item flex flex-col" id="0" :ref="el => blockRefs[0] = el">
            <p class="text4 item__top">01. About company</p>
            <div class="item__text flex flex-col">
              <p class="text2">Version 3.0 from 1st of July 2024</p>
              <p class="text2">GERNE Technology SARL (hereinafter referred to as “we',' “our”, “us” or the “Company”) is a Luxembourg company, with registered address at 17, rue Edmond Reuter, L-5326, Contern, Luxembourg, operating from the website <span>gerne.lu</span> (hereinafter referred to as the “Website”). The Company is committed to providing software development services, IT security services and technical support operations in IT.</p>
            </div>
          </div>
          <div class="item flex flex-col" id="1" :ref="el => blockRefs[1] = el">
            <p class="text4 item__top">02. Introduction</p>
            <div class="item__text flex flex-col">
              <p class="text2">This Policy details our commitment to protecting the privacy of individuals who visit our Website (“Website Visitors”), who register to use the products and services (available at www.gerne.lu (the “Service(s)”).</p>
              <p class="text2">The company is committed to protecting your privacy and handling your data in an open and transparent manner. The personal data that we collect and process depends on the product or service requested and agreed in each case.</p>
              <p class="text2">Effective as of May 18, 2018, GERNE Technology SARL have updated our Privacy Policy ("Policy").</p>
              <p class="text2">Definitions<br/>Controller – "controller" means the natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of personal data; where the purposes and means of such processing, are determined by Union or Member State law, the controller or the specific criteria for its nomination may be provided for by Union or Member State law;</p>
              <p class="text2">Processor – "means a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller";</p>
              <p class="text2">Personal data – "means any information relating to an identified or identifiable natural person (‘data subject’); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person";</p>
              <p class="text2">DPO (Data Protection Officer) – "a person appointed by the Company that takes formal responsibility for data protection compliance within an organisation";</p>
              <p class="text2">Data Protection Authority – "means an independent public authority that is legally tasked with overseeing compliance with applicable data protection laws".</p>
            </div>
          </div>
          <div class="item flex flex-col" id="2" :ref="el => blockRefs[2] = el">
            <p class="text4 item__top">03. What we need</p>
            <div class="item__text flex flex-col">
              <p class="text2">Our Personal Data Protection Policy governs the use and storage of your data.</p>
              <p class="text2">GERNE Technology SARL is a Controller of the personal data you provide to us. We only collect basic personal data about you.</p>
              <p class="text2">Subscriber’s personal data (meaning any information about You from which You can be personally identified, such as Your name, address, telephone number or email address, and which is referred to in this Privacy Notice as "Your Information"), will be processed by GERNE Technology SARL (which include its agents and employees), and (where appropriate) our partners and subcontractors in order to be able to provide the full range of the Services to the Subscriber. Subscriber’s Information is collected when the Subscriber registers to open an account with GERNE Technology SARL; or when the Subscriber submits a written query to GERNE Technology SARL.</p>
              <p class="text2">Whether you have an obligation to provide us with your personal data<br/> In order that we may be in a position to proceed with a business relationship with you, you must provide your personal data to us which are necessary for the required commencement and execution of a business relationship. We are furthermore obligated to collect such personal data given the provisions of the money laundering law which require that we verify your identity before we enter into a business relationship with you or the legal entity for which you are the authorized representative / agent or beneficial owner. You must, therefore, provide us at least with your identity card/passport, your full name, place of birth (city and country), and your residential address so that we may comply with our statutory obligation as mentioned above.</p>
              <p class="text2">Kindly note that if you do not provide us with the required data, then we will not be allowed to commence or continue our business relationship either to you as an individual or as the authorized representative/agent or beneficial owner of a legal entity.</p>
            </div>
          </div>
          <div class="item flex flex-col" id="3" :ref="el => blockRefs[3] = el">
            <p class="text4 item__top">04. Why we need it</p>
            <div class="item__text flex flex-col">
              <p>We need your personal data in order to provide you with the following services:</p>
              <ul>
                <li class="text2">Provide Personal meeting – collection of name and email for the purposes of arranging personal meeting and getting feedback from the GERNE Technology SARL team.</li>
                <li class="text2">Provide Feedback – collection of visitors name, email and telephone number for the purpose of contacting GERNE Technology SARL representatives and receive feedback.</li>
                <li class="text2">Provide Client Maintenance and Support – collecting of clients email, name, phone number for the purpose of performing incoming support for the product end user, handling incoming requests.</li>
                <li class="text2">Provide IT Support – collecting of user IP, name for the purpose of monitoring and IT support of web servers, dns, application servers, business reports, etc.</li>
                <li class="text2">Provide emergency Service – collecting of name and telephone for the purpose of providing emergency services.</li>
              </ul>
              <p class="text2">Website and Web Application Analytics – usage of Google Analytics and Amplitude Analytics: collection of information about users visiting our website and web application, IP addresses, pages visited, duration of visit, type of browser and operating system for the purpose of improving our website and web application. For more information, please refer to Google Analytics and Amplitude Terms of Service and Google and Amplitude Privacy Policy.</p>
              <p class="text2">Website cookies – we use cookies. This technology provides us with personal information: information about devices and networks you utilize to access our Website. For detailed information about the use of cookies in the Website and Web application, please read and review our Cookie Policy.</p>
            </div>
          </div>
          <div class="item flex flex-col" id="4" :ref="el => blockRefs[4] = el">
            <p class="text4 item__top">05. Who receives your personal data</p>
            <div class="item__text flex flex-col">
              <p class="text2">Data Processors list:</p>
              <p class="text2">Your personal data is processed in: GERNE Technology SARL, located at 17, rue Edmond Reuter, L-5326, Contern, Luxembourg. DPO Email address: <span>moien@gerne.lu</span> Software development and storage of Personal Data. <br/>We will never not disclose or sell any personal information to any company outside the list of Data Processors (6.1) above and except to help prevent fraud and/or unlawful usage, or if required to do so by law.</p>
            </div>
          </div>
          <div class="item flex flex-col" id="5" :ref="el => blockRefs[5] = el">
            <p class="text4 item__top">06. Transfer of your personal data to a third country or to an international organization</p>
            <div class="item__text flex flex-col">
              <p class="text2">Your personal data may be transferred to third countries [i.e. countries outside of the European Economic Area] using Signed Standard Contractual Clauses for other third countries according to Article 46 Section 2 lits. c, d GDPR.</p>
              <p class="text2">Processors in third countries are obligated to comply with the European data protection standards and to provide appropriate safeguards in relation to the transfer of your data in accordance with GDPR Article 46.</p>
            </div>
          </div>
          <div class="item flex flex-col" id="6" :ref="el => blockRefs[6] = el">
            <p class="text4 item__top">07. How long we keep your personal information for</p>
            <div class="item__text flex flex-col">
              <p class="text2">Personal Data will be kept in a form which permits identification of data subjects for no longer than is necessary for the purposes for which the personal data are processed; personal data may be stored for longer periods insofar as the personal data will be processed solely for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes or defending any legal rights.</p>
            </div>
          </div>
          <div class="item flex flex-col" id="7" :ref="el => blockRefs[7] = el">
            <p class="text4 item__top">08. Your data protection rights</p>
            <div class="item__text flex flex-col">
              <p class="text2">You have the following rights in terms of your personal data we hold about you:</p>
              <p class="text2">Receive access to your personal data. This enables you to e.g. receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</p>
              <p class="text2">Request correction of the personal data we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected.</p>
              <p class="text2">Request erasure of your personal information. This enables you to ask us to erase your personal data [known as the ‘right to be forgotten’] where there is no good reason for us continuing to process it.</p>
              <p class="text2">Object to the processing of your personal data where we are relying on a legitimate interest and there is something about your particular situation which makes you want to object to processing on this ground. If you lodge an objection, we will no longer process your personal data unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms.</p>
              <p class="text2">Withdraw the consent that you gave us with regard to the processing of your personal data at any time. Note that any withdrawal of consent shall not affect the lawfulness of processing based on consent before it was withdrawn or revoked by you.</p>
              <p class="text2">In the event that you wish to complain about how we handle your personal data, please contact GERNE Technology SARL DPO.</p>
              <p class="text2">Valid Data Subjects Access Requests listed below will be responded up to 30 days:</p>
              <ul>
                <li class="text2">Know whether a data controller holds any personal data about them.</li>
                <li class="text2">Receive a description of the data held about them and, if permissible and practical, a copy of the data.</li>
                <li class="text2">Be informed of the purpose(s) for which that data is being processed, and from where it was received.</li>
                <li class="text2">Be informed whether the information is being disclosed to anyone apart from the original recipient of the data; and if so, the identity of those recipients.</li>
                <li class="text2">Be informed of the data storage period.</li>
                <li class="text2">If the data is being used to make automated decisions about the data subject, to be told what logic the system uses to make those decisions and to be able to request human intervention.</li>
                <li class="text2">According to Article 12 (3) GDPR we will provide a request under Articles 15 to 22 to the data subject without undue delay and in any event within one month of receipt of the request. That period may be extended by two further months where necessary, taking into account the complexity and number of the requests.</li>
              </ul>
              <p class="text2">If any of your personal data we hold is incorrect or incomplete, you can request on legitimate grounds to have the information corrected, restricted or removed by writing to the DPO at GERNE Technology SARL at the above address.</p>
              <p class="text2">To exercise any of your rights, or if you have any other questions about our use of your personal data, please contact the DPO at GERNE Technology SARL at the above address and by completing the Data Subject Consent Withdrawal Form.</p>
            </div>
          </div>
          <div class="item flex flex-col" id="8" :ref="el => blockRefs[8] = el">
            <p class="text4 item__top">09. Right to lodge a complaint</p>
            <div class="item__text flex flex-col">
              <p class="text2">If after complaining to DPO you still feel that your personal data has not been handled appropriately according to the law, you can lodge complaints with a Data Protection Authority regarding the Processing of your Personal Data by us or on our behalf:</p>
              <p class="text2">National Commission for Data Protection<br/>15, Boulevard du Jazz<br/>L-4370 Belvaux</p>
              <p class="text2">Tél.: <span>(+352)2610601</span><br/>Website: <span>https://cnpd.public.lu</span><br/>Email: <span>reclamation@cnpd.lu</span></p>
            </div>
          </div>
          <div class="item flex flex-col" id="9" :ref="el => blockRefs[9] = el">
            <p class="text4 item__top">10. Changes to this privacy statement</p>
            <div class="item__text flex flex-col">
              <p class="text2">We may modify or amend this privacy statement from time to time.</p>
              <p class="text2">We will notify you appropriately when we make changes to this privacy statement and we will amend the revision date at the top of this page. We do however encourage you to review this statement periodically so as to be always informed about how we are processing and protecting your personal information.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.p-privacy {
  padding: 55px 0 106px;
  color: $black-100;
  overflow: inherit;
  &__content {
    margin-top: 40px;
    gap: 30px;
    .body {
      width: 80%;
      max-width: 850px;
      gap: 60px;
      .text2 {
        span {
          font-weight: 700;
          color: $primary;
        }
      }
      ul li {
        margin-left: 24px;
      }
    }
  }
}

.item {
  gap: 36px;
  color: $black-100;
  &__text {
    gap: 24px;
  }
}

@media (max-width: 900px) {
  .p-privacy {
    .section-title {
      display: none;
    }
    &__content {
      width: 100%;
      justify-content: flex-start;
      margin-top: 0;
      .body {
        width: 100%;
        max-width: none;
      }
    }
  }
}

@media (max-width: 700px) {
  .p-privacy {
    padding: 40px 0 90px;
    .item {
      gap: 20px;
      .text4 {
        font-size: 34px;
        font-weight: 700;
        line-height: 44px;
        opacity: 0.92;
      }
    }
  }
}
</style>