import { defineStore } from 'pinia'

export const usePrivacyStore = defineStore({
    id: 'privacy',
    state: () => ({
        blockId: 0,
        blockVisible: true,
        isClicked: false
    }),
    actions: {
        setBlockIndex(index) {
            this.blockId = index
        },
        setBlockVisibility(value) {
            this.blockVisible = value
        },
        setClicked(value) {
            this.isClicked = value
        }
    }
})
