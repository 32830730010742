<script setup>
import { ref } from "vue";
import { formatNumber } from "@/utils/numberConverter.js";
import { usePrivacyStore } from '@/store/privacy';
const content = ref([
  "About company",
  "Introduction",
  "What we need",
  "Why we need it",
  "Who receives your personal data",
  "Transfer of your personal data to a third country or to an international organization",
  "How long we keep your personal information for",
  "Your data protection rights",
  "Right to lodge a complaint",
  "Changes to this privacy statement"
])

const privacyStore = usePrivacyStore();

const handleBlockClick = (index) => {
  privacyStore.setBlockIndex(index);
  privacyStore.setBlockVisibility(true);
  privacyStore.setClicked(true);
}

</script>

<template>
  <div class="wrap">
    <div class="contents flex flex-col">
      <div
          v-for="(item, idx) in content"
          :key="idx"
          class="item flex justify-between items-start relative"
          :class="{'active': privacyStore.blockId === idx}"
          @click="handleBlockClick(idx)"
      >
        <p class="small11 second">{{ item }}</p>
        <p class="small11 second">{{ formatNumber(idx + 1) }}</p>
        <div class="line absolute" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.wrap {
  min-width: 310px;
  position: sticky;
  display: block;
  position: -webkit-sticky;
  top: 50px;
  align-self: flex-start;
  height: auto;
}
.contents {
  gap: 14px;
  .item {
    opacity: 0.62;
    padding-bottom: 14px;
    border-bottom: 1px solid $grey-200;
    cursor: pointer;
    transition: all .3s ease;
    &:hover {
      opacity: 0.8;
    }
    &.active {
      opacity: 1;
      .line {
        width: 100%;
      }
    }
    & > p:first-child {
      max-width: 260px;
    }
    .line {
      height: 1px;
      width: 0;
      bottom: 0;
      left: 0;
      background: $black;
      transition: width .5s ease;
    }
  }
}

@media (max-width: 900px) {
  .contents, .wrap {
    display: none;
  }
}
</style>